// extracted by mini-css-extract-plugin
export var awards = "resume-module--awards--Fexke";
export var bottomhr = "resume-module--bottomhr--sXMbI";
export var btnDiv = "resume-module--btnDiv--4Q-c6";
export var column = "resume-module--column--u+2KB";
export var contact = "resume-module--contact--7N8Sq";
export var content = "resume-module--content--MpVSB";
export var desc = "resume-module--desc--P8Jxd";
export var education = "resume-module--education--8JMyx";
export var experience = "resume-module--experience--ifICE";
export var icon = "resume-module--icon--cETZs";
export var info = "resume-module--info--mNQ4n";
export var language = "resume-module--language--heTvl";
export var left = "resume-module--left--RFDIm";
export var name = "resume-module--name--P1HaW";
export var printPDF = "resume-module--printPDF--vWoG9";
export var projects = "resume-module--projects--eUayM";
export var resume = "resume-module--resume--BgYd6";
export var resumeDiv = "resume-module--resumeDiv--mfsWI";
export var resumeWrapper = "resume-module--resumeWrapper--pzthe";
export var right = "resume-module--right--gfiFo";
export var skillContent = "resume-module--skillContent--ImOjd";
export var skillTitle = "resume-module--skillTitle--kmco6";
export var skills = "resume-module--skills--LFaDN";
export var soft = "resume-module--soft--AsEqo";
export var tech = "resume-module--tech--NQPBO";
export var title = "resume-module--title--xUC-5";
export var top = "resume-module--top--FUulc";
export var topContent = "resume-module--topContent--EufFx";
export var tophr = "resume-module--tophr--jjZ6q";
export var year = "resume-module--year--VpKKm";